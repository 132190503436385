<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <el-icon>
            <menu />
          </el-icon>
          Sending Email
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div class="handle-box">
        <el-input
          v-model="query.title"
          placeholder="Subject"
          class="handle-input mr10"
          @keyup.enter="handleSearch"
          :clearable="true"
        ></el-input>
        <el-button type="primary" @click="handleSearch">
          <el-icon>
            <search />
          </el-icon>
          Search
        </el-button>
        <el-button type="primary" @click="handleCreate" style="float: right">
          <el-icon>
            <plus />
          </el-icon>
          Send Email
        </el-button>
      </div>
      <el-table
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        @sort-change="changeSort"
        :default-sort="{ prop: 'CreatedTime', order: 'ascending' }"
      >
        <el-table-column prop="title" label="Subject"></el-table-column>
        <el-table-column
          prop="status"
          label="Status"
          :width="this.$widthRatio * 100 + 'px'"
        ></el-table-column>
        <el-table-column
          prop="updatedTime"
          sortable="true"
          :sort-orders="['ascending', 'descending', null]"
          :sort-by="UpdatedTime"
          label="Updated Time"
          :width="this.$widthRatio * 120 + 'px'"
          :formatter="$tableCellFormatter.formatDate"
        ></el-table-column>
        <el-table-column
          label="Operation"
          align="center"
          :width="this.$widthRatio * 320 + 'px'"
        >
          <template #default="scope">
            <el-button type="text" @click="handleEdit(scope.$index, scope.row)">
              <el-icon>
                <edit />
              </el-icon>
              Edit & Send
            </el-button>
            <el-button
              v-if="scope.row.status === 'Sent'"
              type="text"
              @click="showSendingResponse(scope.$index, scope.row)"
            >
              <el-icon>
                <tickets />
              </el-icon>
              Sending Feedback
            </el-button>
            <el-button
              type="text"
              class="red"
              @click="handleDelete(scope.$index, scope.row)"
            >
              <el-icon>
                <delete />
              </el-icon>
              Delete
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.pageIndex"
          :page-size="query.pageSize"
          :total="itemTotal"
          :page-count="pageTotal"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>

      <el-dialog :title="dialogTitle" v-model="editVisible" width="90%">
        <el-form
          ref="mainForm"
          :model="form"
          :label-width="this.$widthRatio * 120 + 'px'"
        >
          <el-form-item label="To">
            <el-select
              v-model="form.toValue"
              multiple
              filterable
              allow-create
              default-first-option
              :reserve-keyword="false"
              :fit-input-width="true"
              style="display: block"
              @change="toChanged"
            >
              <el-option
                v-for="item in form.toValue"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
            <el-button type="primary" @click="openImportStudentDialog">
              <el-icon>
                <plus />
              </el-icon>
              Import From Students
            </el-button>
            <el-button type="primary" @click="copyEmails">
              <el-icon>
                <documentCopy />
              </el-icon>
              Copy Email Addresses
            </el-button>
          </el-form-item>
          <el-form-item label="CC">
            <el-select
              v-model="cc"
              value-key="email"
              multiple
              filterable
              allow-create
              default-first-option
              :reserve-keyword="false"
              style="display: block"
              @change="ccChanged"
            >
              <el-option
                v-for="item in form.cc"
                :key="item"
                :label="item.name"
                :value="item.email"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="templateId" label="Email Template">
            <el-select
              v-model="form.templateId"
              value-key="key"
              placeholder="Please select..."
              @change="handleTemplateChange"
              style="display: block"
            >
              <el-option
                v-for="item in options.templateList"
                :key="item"
                :label="item.value"
                :value="item.key"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="subject" label="Subject">
            <el-input v-model="form.subject"></el-input>
          </el-form-item>
          <el-form-item label="Body">
            <ckeditor
              :editor="editor"
              v-model="form.body"
              :config="editorConfig"
            ></ckeditor>
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="editVisible = false">Cancel</el-button>
            <el-button type="primary" @click="saveEdit(true)"> Save & Send </el-button>
            <el-button type="primary" @click="saveEdit(false)"> Save as Draft </el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
  <el-dialog :title="dialogTitle" v-model="showStudentSelectionDialog" width="85%">
    <students-selection
      :initData="{ parent: this, confirmFunction: handleImportStudents }"
    />
  </el-dialog>
</template>

<script>
import { getData, putData, postData, deleteData } from "../../service/api";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import StudentsSelection from "./StudentsSelection.vue";

export default {
  name: "sendingEmail",
  components: { StudentsSelection },
  data() {
    return {
      controllerUrl: "/dataStorage",
      query: {
        storageId: null,
        title: null,
        dataType: "SendingEmail",
        orderBy: "CreatedTime",
        orderDirection: "ASC",
        pageIndex: 1,
        pageSize: 20,
      },
      options: {
        templateList: [],
      },
      showStudentSelectionDialog: false,
      currentTemplate: {},
      currentDataStorage: {},
      editor: ClassicEditor,
      tableData: [],
      dialogTitle: "",
      editVisible: false,
      itemTotal: 0,
      pageTotal: 0,
      form: {
        storageId: null,
        toValue: [],
        ccValue: [],
        to: [],
        cc: [],
        templateId: null,
        subject: null,
        body: null,
        attachment: null,
        isHTML: null,
      },
      idx: -1,
      id: -1,
    };
  },
  created() {
    this.initAndLoadData();
  },
  methods: {
    initAndLoadData() {
      this.loadData();
      this.loadAllTemplates();
    },
    loadData() {
      getData(this.controllerUrl, this.query).then((res) => {
        let resData = res.result;
        this.tableData = resData.list;
        this.query.pageIndex = resData.pageIndex;
        this.query.pageSize = resData.pageSize;
        this.itemTotal = resData.count;
        this.pageTotal = Math.ceil(resData.count / resData.pageSize);
      });
    },
    loadAllTemplates() {
      getData("/generalTemplate/Email/getAllTemplateIdAndName").then((res) => {
        if (res.result && res.code === "200") {
          this.options.templateList = res.result;
        } else {
          this.$message.error(
            "Load email templates failed, error message: " + res.message
          );
        }
      });
    },
    loadTemplate() {
      getData(`/generalTemplate/${this.form.templateId}`).then((res) => {
        if (res.result && res.code === "200") {
          let template = res.result;
          let defaultTo = template.defaultTo;
          if (defaultTo) {
            let arr = defaultTo.split(";");
            let to = [];
            let toValueArr = [];
            for (let i = 0; i < arr.length; i++) {
              to.push({
                receiverId: null,
                name: null,
                email: arr[i],
              });
              toValueArr.push(arr[i]);
            }
            this.form.toValue = toValueArr;
            this.form.to = to;
          } else {
            this.form.to = [];
          }
          let defaultCC = template.defaultCC;
          if (defaultCC) {
            let arr = defaultCC.split(";");
            let cc = [];
            let ccValueArr = [];
            for (let i = 0; i < arr.length; i++) {
              cc.push({
                receiverId: null,
                name: null,
                email: arr[i],
              });
              ccValueArr.push(arr[i]);
            }
            this.form.ccValue = ccValueArr;
            this.form.cc = cc;
          } else {
            this.form.cc = [];
          }
          this.form.subject = template.subject;
          this.form.body = template.content;
          this.form.isHTML = template.enableHTML;
          this.currentTemplate = template;
        } else {
          this.$message.error(
            "Load email template failed, error message: " + res.message
          );
        }
      });
    },
    openImportStudentDialog() {
      this.showStudentSelectionDialog = true;
    },
    closeImportStudentDialog() {
      this.showStudentSelectionDialog = false;
    },
    handleImportStudents(studentList) {
      try {
        let toValueArr = this.form.toValue || [];
        let to = this.form.to || [];
        console.log("handleImportStudents", studentList);
        if (studentList && studentList.length > 0) {
          for (let i = 0; i < studentList.length; i++) {
            let student = studentList[i];
            if (toValueArr.indexOf(student.email) >= 0) continue;
            toValueArr.push(student.email);
            to.push({
              receiverId: student.studentId,
              name: student.userName,
              email: student.email,
            });
          }
          this.form.toValue = toValueArr;
          this.form.to = to;
        }
      } catch {
        // ignore
      } finally {
        this.closeImportStudentDialog();
      }
    },
    toChanged(tos) {
      if (!tos || tos.length <= 0) return;
      let to = [];
      for (let i = 0; i < tos.length; i++) {
        to.push({
          receiverId: null,
          name: null,
          email: tos[i],
        });
      }
      this.form.to = to;
    },
    ccChanged(ccs) {
      if (!ccs || ccs.length <= 0) return;
      let cc = [];
      for (let i = 0; i < ccs.length; i++) {
        cc.push({
          receiverId: null,
          name: null,
          email: ccs[i],
        });
      }
      this.form.cc = cc;
    },
    handleSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    changeSort(sortData) {
      console.log(sortData);
      if (sortData.order) {
        this.query.orderBy = sortData.prop;
        this.query.orderDirection = sortData.order === "ascending" ? "asc" : "desc";
      } else {
        this.query.orderBy = "";
        this.query.orderDirection = "";
      }
      this.loadData();
    },
    handlePageChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },
    handleDelete(index, row) {
      // twice to confirm to delete
      this.$confirm("Are you sure to delete this item?", "Prompt", {
        type: "warning",
      })
        .then(() => {
          console.log(row);
          deleteData(this.controllerUrl, row.storageId).then((res) => {
            if (res.result && res.code === "200") {
              this.$message.success("Delete successfully");
              this.tableData.splice(index, 1);
            } else {
              this.$message.error("Delete failed, error message: " + res.message);
            }
          });
        })
        .catch(() => {});
    },
    handleEdit(index, row) {
      this.dialogTitle = "Edit";
      this.idx = index;
      this.currentDataStorage = row;
      // row is dataStorage, need to deserialize the data
      this.form = JSON.parse(row.data);
      this.form.storageId = row.storageId;
      this.editVisible = true;
      if (this.$refs.mainForm) this.$refs.mainForm.resetFields();
    },
    handleCreate() {
      this.form = {};
      this.currentDataStorage = {};
      this.currentDataStorage.dataType = "SendingEmail";
      this.currentDataStorage.status = "Normal";
      this.dialogTitle = "New";
      this.editVisible = true;
      if (this.$refs.mainForm) this.$refs.mainForm.resetFields();
    },
    async saveEdit(sendingEmail) {
      if (sendingEmail) {
        let sendingResponse = await this.sendingEmail();
        this.form.sendingResponse = sendingResponse || {};
      }

      this.currentDataStorage.title = this.form.subject;
      this.currentDataStorage.summary = this.form.body;
      this.currentDataStorage.data = JSON.stringify(this.form);

      this.$refs.mainForm.validate((valid) => {
        if (valid) {
          if (sendingEmail) {
            this.currentDataStorage.status = "Sent";
          } else {
            this.currentDataStorage.status = "Draft";
          }
          if (
            this.currentDataStorage.storageId &&
            this.currentDataStorage.storageId.length === 22
          ) {
            putData(
              this.controllerUrl,
              this.currentDataStorage.storageId,
              this.currentDataStorage
            ).then((res) => {
              if (res.result && res.code === "200") {
                this.editVisible = false;
                if (!sendingEmail) {
                  this.$message.success("Update successfully");
                }
              } else {
                this.$message.error("Update failed, error message: " + res.message);
              }
            });
          } else {
            postData(this.controllerUrl, this.currentDataStorage).then((res) => {
              console.log(res);
              if (res.result && res.code === "200") {
                this.editVisible = false;
                if (!sendingEmail) {
                  this.$message.success("Create successfully");
                }
                this.form = res.result;
                this.loadData();
              } else {
                this.$message.error("Create failed, error message: " + res.message);
              }
            });
          }
        } else {
          this.$message.error("Validation failed, please correct!");
          return false;
        }
      });
    },
    async sendingEmail() {
      let data = { emailData: this.form };
      console.log("sendingEmail", this.form, data);
      let res = await postData("/sendingEmail", data);
      if (res.result && res.code === "200") {
        let dic = res.result;
        this.showSendingFeedback(dic);
        return dic;
      } else {
        this.$message.error("Sending email failed, error message: " + res.message);
      }
      return null;
    },
    showSendingResponse(index, row) {
      let data = JSON.parse(row.data);
      this.showSendingFeedback(data.sendingResponse);
    },
    showSendingFeedback(dic) {
      let arr = [];
      for (let key in dic) {
        let val = dic[key];
        arr.push(`${key} - ${val}`);
      }
      this.$alert(arr.join("<br>"), "Sending Feedback", {
        dangerouslyUseHTMLString: true,
      });
    },
    handleTemplateChange(templateId) {
      console.log("handleTemplateChange", arguments);
      this.form.templateId = templateId;
      this.loadTemplate();
    },
    copyEmails() {
      let tos = this.form.to;
      let arr = [];
      for (let i = 0; i < tos.length; i++) {
        let to = tos[i];
        arr.push(to.email);
      }
      let _this = this;
      let to = arr.join(";");
      this.$copyText(to).then(
        function () {
          _this.$message.success("Copied");
        },
        function () {
          _this.$message.error("Can not copy");
        }
      );
    },
  },
};
</script>
<style>
.ck-editor__editable {
  min-height: 200px;
}
.el-select__input {
  min-width: auto;
  max-width: auto;
  width: 100%;
}
</style>
<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 200px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 1.17rem;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
</style>
